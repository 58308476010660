import { Button } from '@cocoonspace/ui-admin/components/button'
import { PanelRightCloseIcon, PanelRightOpenIcon } from 'lucide-react'
import { useUserPrefsStore } from '~/stores/user-prefs.store'

export const AppMenu = () => {
	const isSidebarVisible = useUserPrefsStore.use.isSidebarVisible()
	const toggleSidebar = useUserPrefsStore.use.toggleSidebar()

	return (
		<Button
			data-testid='toggle-sidebar-btn'
			size='icon'
			variant='ghost'
			onClick={() => toggleSidebar()}
		>
			{isSidebarVisible ? (
				<PanelRightOpenIcon className='size-4' />
			) : (
				<PanelRightCloseIcon className='size-4' />
			)}
		</Button>
	)
}
