import { useGetMe } from '@cocoonspace/sdk-js/domains/profile/profile'
import {
	Card,
	CardDescription,
	CardFooter,
	CardTitle,
} from '@cocoonspace/ui-admin/components/card'
import { Skeleton } from '@cocoonspace/ui-admin/components/skeleton'
import { ChevronRightIcon } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useMemo } from 'react'
import { useDescribe } from '~/hooks/admin-api/use-describe.hook'
import { useListEntityEntries } from '~/lib/admin-sdk-js/domains/entries/entries'

export const MyQuotationsRecap = () => {
	const { t } = useTranslation()

	const me = useGetMe()
	const describe = useDescribe('quotations')

	const itemsList = useListEntityEntries(
		'quotations',
		{ page_size: 1000 },
		{
			query: {
				select: (res) => {
					const data =
						res.data?.filter(
							({ created_by }) => created_by === me.data?.data.email,
						) || []

					return {
						...res,
						data,
						meta: {
							...res.meta,
							count: data.length,
						},
					}
				},
			},
		},
	)

	const ordersStatuses = useMemo(() => {
		const filter = describe?.filters.find(({ name }) => name === 'status')

		if (!filter) return []

		const ordersByStatus =
			itemsList.data?.data?.reduce((acc, order) => {
				acc[order.status] = acc[order.status] || []
				acc[order.status].push(order)
				return acc
			}, {}) || {}

		return (
			(filter.values as string[])
				?.map((name) => ({
					name,
					value: ordersByStatus[name as keyof typeof ordersByStatus]?.length,
				}))
				.filter(({ value }) => !!value) || []
		)
	}, [describe?.filters, itemsList])

	return (
		<Card className='flex flex-col justify-center text-left'>
			<div className='flex'>
				<div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
					<CardTitle>Mes devis</CardTitle>

					<CardDescription>Liste des statuts de devis.</CardDescription>
				</div>

				<div className='flex min-w-[100px]'>
					<div className='relative z-30 flex flex-1 flex-col justify-center gap-1 border-t border-l px-6 py-4 text-left sm:border-t-0'>
						<span className='text-muted-foreground text-xs'>Total</span>

						<span className='flex flex-row items-end gap-1 font-bold text-lg leading-none sm:text-3xl'>
							{itemsList.isLoading ? (
								<Skeleton className='h-6 w-full' />
							) : (
								itemsList.data?.meta?.count || 0
							)}
						</span>
					</div>
				</div>
			</div>

			<div className='flex flex-col divide-y border-y'>
				{ordersStatuses.map(({ name, value }) => (
					<Link
						key={name}
						href={`/quotations?status=${name}`}
						className='flex items-center justify-between gap-2 px-6 py-2 text-sm'
					>
						<span className='flex-1'>{t(`values:values.status.${name}`)}</span>
						<span>{value}</span>
						<span>
							<ChevronRightIcon className='size-4' />
						</span>
					</Link>
				))}
			</div>

			<CardFooter className='flex justify-end py-3 pb-3'>
				<Link
					href='/quotations'
					className='flex items-center text-primary text-sm leading-none'
				>
					Voir tous les devis
					<ChevronRightIcon className='ml-2 size-4' />
				</Link>
			</CardFooter>
		</Card>
	)
}
